import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectService } from '../../../../api/services/project.service';
import { ToastyService } from '../../../../shared/toasty';
import { BasicDialogComponent } from '../../../../shared/dialogs/basic-dialog/basic.dialog.component';
import { BasicDialogService } from '../../../../services/basic-dialog.service';
import { TypedFormBuilder } from '../../../../form/typed-form-builder';
import { TypedFormGroup } from '../../../../form/typed-form-group';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { ExistingFormAttachmentFile } from '../../index';
import { AttachmentOutdateRequestModel } from '../../../../api/models/requests/attachment-outdate-request.model';
import { AttachmentOutdateResponseModel } from '../../../../api/models/responses/attachment-outdate.response.model';

export interface OutdateAttachmentDialogData {
  /**
   * Should be set if a work package is edited.
   */
  attachment?: ExistingFormAttachmentFile;
}

export type OutdateAttachmentDialogResult = ExistingFormAttachmentFile | undefined;

type MutableRequired<T> = { -readonly [P in keyof T]-?: T[P] }; // Remove readonly and ?

export type OutdateAttachmentForm = MutableRequired<Pick<
ExistingFormAttachmentFile,
| 'fileName'
| 'outdatedComment'
>>;

@Component({
    templateUrl: './outdate-attachment-dialog.component.html',
    standalone: false
})
export class OutdateAttachmentDialogComponent implements OnDestroy {
  /**
   * The project the work package belongs to
   */
  attachment: ExistingFormAttachmentFile;

  /**
   * Contains all the form controls
   */
  form: TypedFormGroup<OutdateAttachmentForm>;

  /**
   * Marks the form as being saved.
   */
  savingForm: boolean = false;

  // =======
  // All form controls of the template for easier access.
  get fileName(): UntypedFormControl { return this.form.controls.fileName as UntypedFormControl; }

  get outdatedComment(): UntypedFormControl { return this.form.controls.outdatedComment as UntypedFormControl; }
  // =======

  private confirmDialogRef: MatDialogRef<BasicDialogComponent> | null = null;

  /**
   * Triggers when the component is destroyed.
   * Necessary to inform other observers.
   *
   * @type {Subject<void>}
   * @private
   */
  private destroyed$: Subject<void> = new Subject();

  constructor(
  @Inject(MAT_DIALOG_DATA) data: OutdateAttachmentDialogData,
    public dialogRef: MatDialogRef<OutdateAttachmentDialogComponent, OutdateAttachmentDialogResult>,
    _formBuilder: UntypedFormBuilder,
    private dialogService: BasicDialogService,
    private projectService: ProjectService,
    private toastyService: ToastyService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    const { attachment } = data;
    // Mandatory
    if (!attachment) {
      throw new Error('Missing required \'attachment\' in dialog data.');
    }

    this.attachment = attachment;

    const formBuilder = _formBuilder as TypedFormBuilder;
    this.form = formBuilder.group({
      fileName: [
        { value: attachment.fileName, disabled: true },
      ],
      outdatedComment: [
        attachment.outdatedComment,
        [Validators.required, Validators.maxLength(50)],
      ],
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();

      return;
    }

    if (this.savingForm) {
      return;
    }

    this.savingForm = true;
    const { outdatedComment } = this.form.getRawValue();
    const request = new AttachmentOutdateRequestModel(
      this.attachment.attachmentId,
      outdatedComment || '',
    );

    this.projectService
      .patchOutdateFile$(
        this.attachment.attachmentId,
        request,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: AttachmentOutdateResponseModel) => {
          this.toastyService.success('Successfully marked attachment as outdated.');
          this.dialogRef.close(this.attachment.clone(response));
          this.savingForm = false;
        },
        (error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'An error has occurred while updating the submitter setting. Please try again.');
          this.savingForm = false;
        },
      );
  }

  cancel(): void {
    if (this.confirmDialogRef) {
      return;
    }

    if (this.form.pristine) {
      this.dialogRef.close();

      return;
    }

    const dialogRef = this.dialogService.showConfirmDialog();
    this.confirmDialogRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((closeConfirmed) => {
        this.confirmDialogRef = null;

        if (closeConfirmed) {
          this.dialogRef.close();
        }
      });
  }
}
