import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ɵstringify as stringify,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EXPANSION_PANEL_ANIMATION_TIMING } from '@angular/material/expansion';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Subject } from 'rxjs';
import {
  animate, state, style, transition, trigger,
} from '@angular/animations';
import { LevelImportListDtoModel } from '../../api/models/dtos/level-import-list.dto.model';
import { formatDate } from '../../collapp-common';
import { LevelModelTreeNode } from './level-model-tree-node';
import { ProjectTypeCode } from '../../models/project-type.enum';
import { CollappDateAdapter, createMissingDateImplError } from '../../collapp-core';

/**
 * Provider Expression that allows collapp-project-tree-structure to register
 * as a ControlValueAccessor. This allows it to support [(ngModel)] and ngControl.
 */
export const COLLAPP_PROJECT_TREE_STRUCTURE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define, @angular-eslint/no-forward-ref
  useExisting: forwardRef(() => ProjectTreeStructureComponent),
  multi: true,
};

@Component({
    selector: 'collapp-project-tree-structure',
    exportAs: 'collappProjectTreeStructure',
    templateUrl: './project-tree-structure.component.html',
    styleUrls: ['./project-tree-structure.component.scss'],
    providers: [
        COLLAPP_PROJECT_TREE_STRUCTURE_CONTROL_VALUE_ACCESSOR,
    ],
    animations: [
        trigger('indicatorRotate', [
            state('collapsed, void', style({ transform: 'rotate(-90deg)' })),
            state('expanded', style({ transform: 'rotate(0deg)' })),
            transition('expanded <=> collapsed, void => collapsed', animate(EXPANSION_PANEL_ANIMATION_TIMING)),
        ]),
    ],
    standalone: false
})
export class ProjectTreeStructureComponent
  implements OnInit, AfterContentInit, OnDestroy, ControlValueAccessor {
  @HostBinding('class.collapp-project-tree-structure')
  readonly hostClass: boolean = true;

  @Input()
  project!: { projectNumber: string; projectTypeCode: ProjectTypeCode };

  /** Whether the labels should appear after or before the checkboxes. Defaults to 'after' */
  @Input()
  get labelPosition(): 'before' | 'after' {
    return this._labelPosition;
  }

  set labelPosition(value: 'before' | 'after') {
    this._labelPosition = (value === 'before' ? 'before' : 'after');
  }

  /**
   * Value for the project-tree-structure.
   */
  @Input()
  get value(): LevelImportListDtoModel[] | null | undefined { return this._value; }

  set value(value: LevelImportListDtoModel[] | null | undefined) {
    this.writeValue(value);
  }

  /** Whether the project tree structure is disabled */
  @Input()
  get disabled(): boolean { return this._disabled; }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  /** Whether the project tree structure is required */
  @Input()
  get required(): boolean { return this._required; }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  nestedTreeControl: NestedTreeControl<LevelModelTreeNode>;

  nestedDataSource: MatTreeNestedDataSource<LevelModelTreeNode>;

  /** Whether the `value` has been set to its initial value. */
  private _isInitialized: boolean = false;

  /** Whether a change to the `value` needs to be triggered on the next change detection. */
  private _triggerChange: boolean = false;

  /** Whether the labels should appear after or before the checkboxes. Defaults to 'after' */
  private _labelPosition: 'before' | 'after' = 'after';

  /** Whether the project tree structure is disabled. */
  private _disabled: boolean = false;

  /** Whether the project tree structure is required. */
  private _required: boolean = false;

  private _value: LevelImportListDtoModel[] | null | undefined;

  private dateAdapter: CollappDateAdapter;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private _changeDetector: ChangeDetectorRef,
    @Optional() dateAdapter?: CollappDateAdapter,
  ) {
    if (!dateAdapter) {
      throw createMissingDateImplError(stringify(ProjectTreeStructureComponent));
    }
    this.dateAdapter = dateAdapter;

    this.nestedTreeControl = new NestedTreeControl<LevelModelTreeNode>(this.getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
  }

  ngOnInit(): void {
    if (!this.project) {
      throw new Error('Attribute \'project\' is required');
    }
  }

  /**
   * Initialize properties once content children are available.
   * This allows us to propagate relevant attributes to associated buttons.
   */
  ngAfterContentInit(): void {
    // Mark this component as initialized in AfterContentInit because the initial value can
    // possibly be set by NgModel on ProjectTreeStructureComponent, and it is possible that
    // the OnInit of the NgModel occurs *after* the OnInit of the ProjectTreeStructureComponent.
    this._isInitialized = true;

    if (this._triggerChange) {
      this._triggerChange = false;
      this.updateValueFromDataSourceAndTriggerChange();
      // @TODO This *will* throw an ExpressionChangedAfterItHasBeenCheckedError.
      // Currently no way to prevent that *but* this method is a last resort fail safe and will most likely
      // never be triggered. Errors are already prevented trough sanitizing LevelImportListDtoModel in the
      // constructor.
    }

    this.expandAll();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // === ControlValueAccessor ===

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   *
   * @param value
   */
  writeValue(value: LevelImportListDtoModel[] | null | undefined): void {
    const source = (Array.isArray(value) ? value : []);
    const data = source
      .map((level) => LevelModelTreeNode.fromSimilarObject(level, undefined, 0));

    this._value = value;
    this.nestedDataSource.data = data;

    if (this.hasChangesAfterMapping(data, source)) {
      if (this._isInitialized) {
        this.updateValueFromDataSourceAndTriggerChange();
      } else {
        this._triggerChange = true;
      }
    }
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   *
   * @param fn - Callback to be registered.
   */
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   *
   * @param fn - Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   *
   * @param {boolean} isDisabled - Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // === /ControlValueAccessor ===

  /**
   * Whether the given node has any child nodes, i.e. is expandable.
   */
  hasChild = (_: number, nodeData: LevelModelTreeNode): boolean => nodeData.expandable;

  getExpandedState(node: LevelModelTreeNode): 'expanded' | 'collapsed' {
    return this.nestedTreeControl.isExpanded(node) ? 'expanded' : 'collapsed';
  }

  getOldValueFor(property: keyof LevelImportListDtoModel, node: LevelModelTreeNode): string | undefined {
    if (property in node.changes) {
      return node.changes[property]?.oldValue;
    }

    return undefined;
  }

  getOldValueTooltipFor(property: keyof LevelImportListDtoModel, node: LevelModelTreeNode): string | null {
    let value = this.getOldValueFor(property, node);
    if (value == null) {
      return null;
    }

    if (value !== '') {
      switch (property) {
        case 'startDate':
        case 'endDate': {
          const date = this.dateAdapter.deserialize(value);
          value = (date != null
            ? formatDate(date, 'date')
            : `Invalid date "${value}"`
          );
          break;
        }
        default:
          break;
      }
    } else {
      value = 'n/a';
    }

    return `Previous value: ${value}`;
  }

  /**
   * Toggle the project structure item selection. Select/deselect all the descendant nodes.
   *
   * @param {LevelModelTreeNode} node
   */
  projectStructureItemSelectionToggle(node: LevelModelTreeNode): void {
    if (node.isDisabled) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    node.isSelected = !node.isSelected;

    const updateChildrenRecursive = (children: LevelModelTreeNode[], selected: boolean): void => {
      children.forEach((child) => {
        if (!child.isDisabled) {
          // eslint-disable-next-line no-param-reassign
          child.isSelected = selected;
        }
        if (child.children.length > 0) {
          updateChildrenRecursive(child.children, selected);
        }
        child.updateSelectedStates();
      });
    };

    if (node.children.length > 0) {
      updateChildrenRecursive(node.children, node.isSelected);
    }

    let parent: LevelModelTreeNode | null = node;
    while (parent) {
      parent.updateSelectedStates();
      parent = parent.parent;
    }
    this.updateValueFromDataSourceAndTriggerChange();
  }

  /**
   * Toggles the expanded state of all nodes.
   */
  toggleExpansion(): void {
    const expanded = this.nestedTreeControl
      .dataNodes
      .find((node) => this.nestedTreeControl.isExpanded(node));

    if (expanded) {
      this.collapseAll();
    } else {
      this.expandAll();
    }
  }

  /**
   * Expands all data nodes in the tree.
   */
  expandAll(): void {
    const levels = this.nestedDataSource.data || [];
    levels.forEach((level) => {
      this.nestedTreeControl.expandDescendants(level);
    });
  }

  /**
   * Collapse all dataNodes in the tree.
   */
  collapseAll(): void {
    const levels = this.nestedDataSource.data || [];
    levels.forEach((level) => {
      this.nestedTreeControl.collapseDescendants(level);
    });
  }

  /**
   * Selects all data nodes in the tree.
   */
  selectAll(): void {
    const selectRecursive = (levels: LevelModelTreeNode[]): void => {
      levels.forEach((level) => {
        if (!level.isDisabled) {
          // eslint-disable-next-line no-param-reassign
          level.isSelected = true;
        }
        if (level.children.length > 0) {
          selectRecursive(level.children);
        }
      });
    };
    selectRecursive(this.nestedDataSource.data);
  }

  /**
   * Deselects all data nodes in the tree.
   */
  deselectAll(): void {
    const deselectRecursive = (levels: LevelModelTreeNode[]): void => {
      levels.forEach((level) => {
        if (!level.isDisabled) {
          // eslint-disable-next-line no-param-reassign
          level.isSelected = false;
        }
        if (level.children.length > 0) {
          deselectRecursive(level.children);
        }
      });
    };
    deselectRecursive(this.nestedDataSource.data);
  }

  private getChildren = (node: LevelModelTreeNode): LevelModelTreeNode[] => node.children;

  /**
   * Checks data and source (which should be identical arrays in size and the amount of children)
   * for differences in the `isSelected` property.
   */
  private hasChangesAfterMapping(data: LevelModelTreeNode[], source: readonly LevelImportListDtoModel[]): boolean {
    const { length } = data;
    for (let i = 0; i < length; i += 1) {
      if (
        data[i].isSelected !== source[i].isSelected
        || this.hasChangesAfterMapping(data[i].children, source[i].children)
      ) {
        return true;
      }
    }

    return false;
  }

  private updateValueFromDataSourceAndTriggerChange(): void {
    // Prevent external modification of object properties by always providing a copy of the model
    this._value = this.nestedDataSource
      .data
      .map((level) => LevelImportListDtoModel.fromSimilarObject(level));
    this._onChange(this._value);
  }

  /** The method to be called in order to update ngModel */
  private _onChange: (value: any) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  /** onTouch function registered via registerOnTouch (ControlValueAccessor) */
  private _onTouched: () => any = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
}
