import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from '../models/project-status.enum';

@Pipe({
    name: 'collAppProjectStatus',
    standalone: false
})
export class CollAppProjectStatusPipe implements PipeTransform {
  transform(value?: ProjectStatus): any {
    if (value == null) {
      return '';
    }

    if (value === ProjectStatus.Active) {
      return 'Active';
    }

    if (value === ProjectStatus.Archived) {
      return 'Archived';
    }

    throw new Error(`Unknown project status '${value}'`);
  }
}
