import { Selector } from '@ngxs/store';
import { ProjectState } from './project.state';
import { ProjectStateModel } from './project-state.model';
import { ProjectViewResponseModel } from '../../api/models/responses/project-view.response.model';
import { LevelListNestedDtoModel } from '../../api/models/dtos/level-list-nested.dto.model';
import { ProjectScopeViewResponseModel } from '../../api/models/responses/project-scope-view.response.model';
import { ProjectActivityViewResponseModel } from '../../api/models/responses/project-activity-view.response.model';
import { WorkPackageViewResponseModel } from '../../api/models/responses/work-package-view.response.model';

export class ProjectSelectors {
  @Selector([ProjectState])
  static projectView(state: ProjectStateModel): ProjectViewResponseModel | null {
    return state.projectView;
  }

  @Selector([ProjectState])
  static projectStructure(state: ProjectStateModel): LevelListNestedDtoModel[] {
    return state.projectStructure;
  }

  @Selector([ProjectState])
  static projectScopeView(state: ProjectStateModel): ProjectScopeViewResponseModel | null {
    return state.projectScopeView;
  }

  @Selector([ProjectState])
  static projectActivityView(state: ProjectStateModel): ProjectActivityViewResponseModel | null {
    return state.projectActivityView;
  }

  @Selector([ProjectState])
  static workPackageView(state: ProjectStateModel): WorkPackageViewResponseModel | null {
    return state.workPackageView;
  }

  @Selector([ProjectState])
  static projectState(state: ProjectStateModel): ProjectStateModel | null {
    return state;
  }
}
