import {
  Optional, Pipe, PipeTransform, ɵstringify as stringify,
} from '@angular/core';
import moment from 'moment';
import { CollappDateAdapter } from '../../collapp-core';
import { isEmpty } from '../../helpers/utilities';

@Pipe({
    name: 'toMoment',
    standalone: false
})
export class ToMomentPipe implements PipeTransform {
  private dateAdapter: CollappDateAdapter;

  constructor(
  @Optional() dateAdapter?: CollappDateAdapter,
  ) {
    if (!dateAdapter) {
      throw new Error(`${stringify(ToMomentPipe)}: No provider found for MomentDateAdapter.`);
    }
    this.dateAdapter = dateAdapter;
  }

  /**
   * Converts the date input into a moment date object.
   */
  transform(value: moment.MomentInput | null | undefined): moment.Moment | null {
    if (isEmpty(value)) {
      return null;
    }

    try {
      return (moment.isMoment(value)
        ? value
        : moment(value)
          .parseZone()
      );
    } catch (error) {
      throw new Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(ToMomentPipe)}'`);
    }
  }
}
