import { Pipe, PipeTransform } from '@angular/core';
import { ContractHourlyRateCategoryPairDtoModel } from '../../api/models/dtos/contract-hourly-rate-category-pair.dto.model';
import { isEmpty } from '../../helpers/utilities';

export function getTooltipFromContractHourlyRateCategoryPairDtoModel(
  item: ContractHourlyRateCategoryPairDtoModel,
): string {
  return `Submitter(${item.submitterHourlyRateCategory}) / Supplier(${item.supplierHourlyRateCategory})`;
}

@Pipe({
    name: 'chrcPairTooltip',
    standalone: false
})
export class chrcPairTooltipPipe implements PipeTransform {
  transform(value: ContractHourlyRateCategoryPairDtoModel): string|null {
    if (isEmpty(value)) {
      return null;
    }

    return getTooltipFromContractHourlyRateCategoryPairDtoModel(value);
  }
}
