import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseDataSelectors } from '../../state/base-data';

@Pipe({
    name: 'collAppProjectType',
    standalone: false
})
export class CollAppProjectTypePipe implements PipeTransform {
  constructor(
    private store: Store,
  ) {
  }

  transform(value?: string): any {
    if (value == null) {
      return '';
    }

    const projectType = this.store.selectSnapshot(BaseDataSelectors.projectTypeByCode(value));

    if (projectType) {
      return projectType.name;
    }

    throw new Error(`Unknown project type '${value}'`);
  }
}
