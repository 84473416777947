/**
 * This module is the entry for your App.
 *
 * Make sure to use the 3 constant APP_ imports so you don't have to keep
 * track of your root app dependencies here. Only import directly in this file if
 * there is something that is specific to the environment.
 */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MsalModule } from '@azure/msal-angular';
import moment from 'moment';

import { APP_DECLARATIONS } from './app.declarations';
import { APP_PROVIDERS } from './app.providers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MsalRedirectComponent } from './msal-redirect.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgSelectMaterialModule } from './components/ng-select-mat';
import { ApplicationInsightsModule } from './modules/application-insights';

import { MaterialModule } from './material.module';
import { CollappMomentDateModule } from './collapp-moment-date-adapter';
import { CollappCommonModule } from './collapp-common';
import { SharedModule } from './shared';
import { environment } from '../environments/environment';
import { TimecardViewExpandsState } from './state/settings/timecard-view-expands/timecard-view-expands.state';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomerMissingTranslationHandler } from './helpers/CustomMissingTranslationHandler.class';
import { getEndpointUrl } from './api/utilities/api.utility';

/*
 * Modifies moment to always include the current time zone when calling toJSON.
 * @see https://momentjs.com/docs/#/displaying/as-json/
 */
moment.fn.toJSON = function toJSON(): string {
  return this.utcOffset(0, true).toISOString(true);
};

/**
 * Create a custom translation loader
 * @param httpClient
 * @returns {TranslateHttpLoader}
 */
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, getEndpointUrl(`/languages/`) , '');
}

/**
 * Factory for CustomerMissingTranslationHandler
 *
 * @returns {CustomerMissingTranslationHandler}
 */
export function createCustomerMissingTranslationHandler(): CustomerMissingTranslationHandler {
  return new CustomerMissingTranslationHandler();
}

@NgModule({ declarations: [
        AppComponent,
        MsalRedirectComponent,
        APP_DECLARATIONS,
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent,
    ], imports: [MsalModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule.forRoot(),
        TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useFactory: createCustomerMissingTranslationHandler
            },
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        ReactiveFormsModule,
        NgSelectMaterialModule,
        ApplicationInsightsModule.forRoot({
            instrumentationKey: environment.instrumentationKey,
        }),
        NgxsModule.forRoot([TimecardViewExpandsState], {
            // developmentMode: environment.ngxsDevelopmentModeEnabled,
            // @TODO Check https://github.com/ngrx/platform/issues/2404 (it's from NGRX, not NGXS, but same issue).
            developmentMode: false,
        }),
        NgxsStoragePluginModule.forRoot({
            keys: ['baseData'],
        }),
        CollappMomentDateModule,
        CollappCommonModule,
        SharedModule.forRoot()], providers: [APP_PROVIDERS, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
