/* eslint-disable rxjs-angular/prefer-takeuntil */
import {
  Component, Inject, ViewEncapsulation, OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Enum for basic dialog types.
 *
 * @enum {number}
 */
export enum BasicDialogType {
  INFO,
  ERROR,
  PROMPT,
  NAVIGATION_PROMPT,
}

export interface BasicDialogData {
  type: BasicDialogType;
  title: string;
  text: string;
  confirmationCallback?: () => Observable<boolean | void>;
  saveAndContinueCallback?: () => Observable<boolean | void>;
  discardAndContinueCallback?: () => Observable<boolean |void>;
}

@Component({
    templateUrl: './basic.dialog.component.html',
    styleUrls: ['./basic.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasicDialogComponent implements OnDestroy {
  get type(): BasicDialogType { return this.data.type; }

  get title(): string { return this.data.title; }

  get text(): string { return this.data.text; }

  loading: boolean = false;

  basicDialogType: typeof BasicDialogType = BasicDialogType;

  private readonly destroyed$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: BasicDialogData,
  ) { }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * This closes the dialog, after the confirm callback has completed.
   */
  confirm(): void {
    this.doCallbackAndClose(this.data.confirmationCallback);
  }

  saveAndContinue(): void {
    this.doCallbackAndClose(this.data.saveAndContinueCallback);
  }

  ignoreAndContinue(): void {
    this.doCallbackAndClose(this.data.discardAndContinueCallback);
  }

  closeAndAbort(): void {
    this.closeDialog(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private doCallbackAndClose(callbackRef: any): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (callbackRef) {
      callbackRef().pipe(
        take(1),
      ).subscribe((successful?: boolean) => {
        this.closeDialog((successful));
      });
    } else {
      this.closeDialog(true);
    }
  }

  private closeDialog(successful?: boolean): void {
    this.loading = false;
    this.dialogRef.close(successful);
  }
}
