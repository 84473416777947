// This component is part of @azure/msal-angular and can be imported and bootstrapped
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-redirect', // Selector to be added to index.html
    template: '',
    standalone: false
})
export class MsalRedirectComponent implements OnInit, OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject();

  constructor(private authService: MsalService) { }

  ngOnInit(): void {
    this.authService.handleRedirectObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
