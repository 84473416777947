import { Pipe, PipeTransform } from '@angular/core';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { isEmpty } from '../../../helpers/utilities';

@Pipe({
    name: 'collAppSubmitterAndSupplierSettingsItemLabel',
    standalone: false
})
export class CollAppSubmitterAndSupplierSettingsItemLabelPipe implements PipeTransform {
  transform(value?: string | null, fallbackValue?: string | null): string | null {
    if (isEmpty(value)) {
      return null;
    }

    switch (value) {
      case ProjectTypeCode.InternalOrder:
      case ProjectTypeCode.ServiceOrder:
        return 'Category';
      case ProjectTypeCode.SalesOrder:
        return 'Item';
      case ProjectTypeCode.ProjectWithNetworkPlanning1:
      case ProjectTypeCode.ProjectWithNetworkPlanning2:
      case ProjectTypeCode.ProjectWithNetworkPlanning3:
        return 'Activity';
      default:
        return this.transform(fallbackValue);
    }
  }
}
