import { Pipe, PipeTransform, ɵstringify as stringify } from '@angular/core';
import { Moment } from 'moment';
import { isEmpty } from '../../helpers/utilities';
import { DateFormatPatterns, formatDate } from '../date';

@Pipe({
    name: 'momentUtc',
    standalone: false
})
export class MomentUtcPipe implements PipeTransform {
  /**
   * Converts the date into UTC time and outputs a string according to the given format.
   *
   * @param [keepLocalTime=false] - Passing true will keep the same local time, but at the expense of choosing
   * a different point in Universal Time.
   */
  transform(
    value: Moment | null | undefined,
    format?: DateFormatPatterns | string,
    keepLocalTime?: boolean,
  ): string | null {
    if (isEmpty(value)) {
      return null;
    }

    try {
      return formatDate(value, format, 0, keepLocalTime);
    } catch (error) {
      throw new Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(MomentUtcPipe)}'`);
    }
  }
}
