import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationInsightsService } from '../modules/application-insights';

/** Pass untouched request through to the next request handler. */
@Injectable({
  providedIn: 'root',
})
export class AppInsightsInterceptor implements HttpInterceptor {
  constructor(
    private appInsightsService: ApplicationInsightsService,
  ) {}

  /** @inheritdoc */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.appInsightsService.trackDependency(UtilHelpers.newId(), request.method, request.urlWithParams);
    this.appInsightsService.trackEvent(
      `${request.method} ${request.url}`,
      {
        body: request.body,
        params: request.params ? request.params
          .keys()
          .map((key) => `${key}=${request.params.get(key)}`)
          .join('&') : '',
      },
    );

    return next.handle(request);
  }
}
