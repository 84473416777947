import {
  Component, Inject, OnDestroy, OnInit, ViewEncapsulation,
  effect,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { of, Subject } from 'rxjs';
import {
  catchError, distinctUntilChanged, filter, mapTo, switchMap, takeUntil,
  tap,
} from 'rxjs/operators';
import { UnitService } from '../../../../../api/services/unit.service';
import { ContractDtoModel } from '../../../../../api/models/dtos/contract.dto.model';
import { CreateUnit, DisableUnit, UpdateUnit } from '../../../../../state';
import { UnitLevel } from '../../../../../models/unit-level.enum';
import { UnitCreateRequestModel } from '../../../../../api/models/requests/unit-create.request.model';
import { UnitUpdateRequestModel } from '../../../../../api/models/requests/unit-update.request.model';
import { ToastyService } from '../../../../../shared/toasty';
import { BasicDialogService } from '../../../../../services/basic-dialog.service';
import { Unit } from '../../../../../models/unit.interface';
import { UnitViewResponseModel } from '../../../../../api/models/responses/unit-view.response.model';
import { BasicUser } from '../../../../../models/user.interface';
import { UnitContractForm } from '../../components/unit-contract-list-control/unit-contract-list-control.component';
import { TypedFormBuilder } from '../../../../../form/typed-form-builder';
import { UnitSelectFetchType } from '../../../../../components/unit-select';
import { AccessControlService } from '../../../../../services/access-control.service';
import { NonProductiveCategoryModel } from '../../../../../models/non-productive-category.model';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { TypedFormGroup } from '../../../../../form/typed-form-group';
import { UnitUpdateRequest } from '../../../../../api/interfaces/requests/unit-update.request';
import { CostCenterService } from 'src/app/services/cost-center.service';

export const HIDDEN_LEVELS_DEFAULT = '0-0-00,0-0-00-FIN,0-0-00-FRP,0-0-00-PWA,0-0-00-RCT,0-0-00-RPI,0-0-00-RRP,0-0-00-TBS,0-0-01-101';

export interface EditUnitForm {
  unitId: number;
  code: string;
  name: string;
  unitCoordinator: BasicUser;
  parentUnit: Unit;
  defects: string[];
  directUserSubmissionOfTimecard: boolean;
  deputyUnitCoordinators: BasicUser[];
  submitterContracts: UnitContractForm[];
  removedSubmitterContractIds: number[];
  timesheetExportTemplateColumns: string;
  timesheetExportTemplateHighlighting: string;
  timesheetExportNonProductiveTemplateColumns: string;
  timesheetExportNonProductiveTemplateHighlighting: string;
  nonProductiveCategories: NonProductiveCategoryModel[];
  hiddenLevels: string;
  payrollCompany: string;
  profitCenter: string;
}

export interface EditUnitDialogData {
  unit?: UnitViewResponseModel;
}

@Component({
    templateUrl: './edit-unit-dialog.component.html',
    styleUrls: ['./edit-unit-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EditUnitDialogComponent implements OnInit, OnDestroy {
  payrollCompanies = signal<string[]>([]);

  isLoadingPayrollCompanies = signal<boolean>(false);

  profitCenters = signal<string[]>([]);

  isLoadingProfitCenters = signal<boolean>(false);

  /**
   * The project the work package belongs to
   */
  unit?: UnitViewResponseModel;

  /**
   * Contains all the form controls
   */
  form: TypedFormGroup<EditUnitForm>;

  /**
   * Marks the form as being saved
   */
  saving: boolean = false;

  /**
   * Set to True if the Form is in Edit Mode
   */
  isFormInEditMode: boolean = false;

  /**
   * This flag is set to true, if the unit will be or is on level 4.
   * Contracts should only be available to level 4 units.
   */
  level: UnitLevel = UnitLevel.UNKNOWN;

  /**
   * A list of units that should be disabled for
   * unit selectors.
   */
  disabledUnitItems: Unit[] = [];

  isLoadingDefectList: boolean = false;

  isLoadingDefaultNonProductiveCategories: boolean = false;

  isLoadingTimesheetExportTemplate: boolean = false;

  fetchTypes: typeof UnitSelectFetchType = UnitSelectFetchType;

  get payroleCompany(): UntypedFormControl { return this.form.controls.payrollCompany as UntypedFormControl; }

  get profitCenter(): UntypedFormControl { return this.form.controls.profitCenter as UntypedFormControl; }

  get code(): UntypedFormControl { return this.form.controls.code as UntypedFormControl; }

  get name(): UntypedFormControl { return this.form.controls.name as UntypedFormControl; }

  get unitCoordinator(): UntypedFormControl { return this.form.controls.unitCoordinator as UntypedFormControl; }

  get defects(): UntypedFormControl { return this.form.controls.defects as UntypedFormControl; }

  get parentUnit(): UntypedFormControl { return this.form.controls.parentUnit as UntypedFormControl; }

  get deputyUnitCoordinators(): UntypedFormControl { return this.form.controls.deputyUnitCoordinators as UntypedFormControl; }

  get submitterContracts(): UntypedFormControl { return this.form.controls.submitterContracts as UntypedFormControl; }

  get removedSubmitterContractIds(): UntypedFormControl {
    return this.form.controls.removedSubmitterContractIds as UntypedFormControl;
  }

  get nonProductiveCategories(): UntypedFormControl {
    return this.form.controls.nonProductiveCategories as UntypedFormControl;
  }

  get timesheetExportTemplateColumns(): UntypedFormControl {
    return this.form.controls.timesheetExportTemplateColumns as UntypedFormControl;
  }

  get timesheetExportTemplateHighlighting(): UntypedFormControl {
    return this.form.controls.timesheetExportTemplateHighlighting as UntypedFormControl;
  }

  get timesheetExportNonProductiveTemplateColumns(): UntypedFormControl {
    return this.form.controls.timesheetExportNonProductiveTemplateColumns as UntypedFormControl;
  }

  get timesheetExportNonProductiveTemplateHighlighting(): UntypedFormControl {
    return this.form.controls.timesheetExportNonProductiveTemplateHighlighting as UntypedFormControl;
  }

  get directUserSubmissionOfTimecard(): UntypedFormControl {
    return this.form.controls.directUserSubmissionOfTimecard as UntypedFormControl;
  }

  get canSeeTimesheetExportTemplate(): boolean {
    return this.isAnyAdmin && this.isLevel3OrBelow;
  }

  get canEditUnitTimesheetExportTemplate(): boolean {
    return this.isAnyAdmin && this.isLevel3;
  }

  get areTimesheetExportTemplateColumnsRequired(): boolean {
    return this.canEditUnitTimesheetExportTemplate;
  }

  get areTimesheetExportNonProductiveTemplateColumnsRequired(): boolean {
    return this.canEditUnitTimesheetExportTemplate;
  }

  get hasTimesheetExportTemplateFormErrors(): boolean {
    return (
      this.timesheetExportTemplateColumns.invalid
      || this.timesheetExportNonProductiveTemplateColumns.invalid
    );
  }

  get isLevel3(): boolean {
    return this.level === UnitLevel.Level3;
  }

  get isLevel3OrBelow(): boolean {
    return this.level >= UnitLevel.Level3;
  }

  get isLevel4(): boolean {
    return this.level === UnitLevel.Level4;
  }

  get isLevel4OrBelow(): boolean {
    return this.level >= UnitLevel.Level4;
  }

  get isSubmitButtonEnabled(): boolean {
    return this.form.valid
      && !this.saving
      && !this.isLoadingDefectList
      && !this.isLoadingDefaultNonProductiveCategories
      && !this.isLoadingTimesheetExportTemplate;
  }

  private isAnyAdmin: boolean = false;

  /**
   * Triggers when the component is destroyed.
   * Necessary to inform other observers.
   *
   * @type {Subject<void>}
   * @private
   */
  private destroyed$: Subject<void> = new Subject();

  // eslint-disable-next-line max-lines-per-function
  constructor(
  @Inject(MAT_DIALOG_DATA) data: EditUnitDialogData,
    public dialogRef: MatDialogRef<EditUnitDialogComponent>,
    _formBuilder: UntypedFormBuilder,
    private unitService: UnitService,
    private errorHandlerService: ErrorHandlerService,
    private dialogService: BasicDialogService,
    private accessControlService: AccessControlService,
    private store: Store,
    private toastyService: ToastyService,
    public costCenterService: CostCenterService,
  ) {
    this.unit = data.unit;
    const formBuilder: TypedFormBuilder = _formBuilder as any;
    this.isAnyAdmin = this.accessControlService.isAnyAdmin();
    this.isFormInEditMode = !!this.unit;

    let disableUnitDetails = false;

    if (this.unit) {
      // Edit mode: Make sure user is allowed to edit fields
      disableUnitDetails = !this.unit.canEditUnitDetails;
    }

    this.form = formBuilder.group<EditUnitForm>({
      unitId: [
        [],
      ],
      code: [
        { value: undefined, disabled: disableUnitDetails },
        [Validators.required],
      ],
      name: [
        { value: undefined, disabled: disableUnitDetails },
        [Validators.required],
      ],
      unitCoordinator: [
        { value: undefined, disabled: false },
        [Validators.required],
      ],
      parentUnit: [
        { value: undefined, disabled: disableUnitDetails },
      ],
      defects: [
        [],
      ],
      deputyUnitCoordinators: [
        [],
      ],
      submitterContracts: [
        [],
      ],
      removedSubmitterContractIds: [
        [],
      ],
      timesheetExportTemplateColumns: [
        { value: '', disabled: false },
      ],
      timesheetExportTemplateHighlighting: [
        { value: '', disabled: false },
      ],
      timesheetExportNonProductiveTemplateColumns: [
        { value: '', disabled: false },
      ],
      timesheetExportNonProductiveTemplateHighlighting: [
        { value: '', disabled: false },
      ],
      nonProductiveCategories: [
        [],
      ],
      directUserSubmissionOfTimecard: this.unit?.directUserSubmissionOfTimecard,
      payrollCompany: [
        { value: '', disabled: false },
      ],
      profitCenter: [
        { value: '', disabled: true },
      ],
      hiddenLevels: [
        { value: HIDDEN_LEVELS_DEFAULT, disabled: false },
      ],
    });
    effect(() => {
      if (this.isLevel4 && this.costCenterService.isDirty()) {
        this.form.markAsDirty();
      }
    });
  }

  ngOnInit(): void {
    this.initParentUnitListeners();
    this.patchForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Creates the necessary DTOs out of the form,
   * to either create a new work package or edit an existing.
   *
   * Preconditions: Form has to be valid.
   */
  // eslint-disable-next-line complexity
  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.costCenterService.showErrors.set(true);
    if (this.isLevel4 && this.costCenterService.errors().length) {
      return;
    }

    if (this.saving) {
      return;
    }

    this.saving = true;

if (!this.unit) {
      this.createUnit();
    } else {
      this.editUnit();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  /**
   * Add IDs of contracts that need to be removed.
   * @param contract
   */
  onSubmitterContractRemoved(contract: ContractDtoModel): void {
    if (contract) {
      const removedSubmitterContractIds: number[] = this.removedSubmitterContractIds.value;
      this.removedSubmitterContractIds.patchValue([...removedSubmitterContractIds, contract.contractId]);
    }
  }

  disableUnit(): void {
    if (!this.unit) {
      return;
    }

    this.unitService
      .disableUnit$(this.unit.unitId)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        if (!this.unit) {
          return;
        }

        // eslint-disable-next-line rxjs/no-ignored-observable
        this.store.dispatch(new DisableUnit(this.unit.unitId));

        this.unit.isDisabled = true;
        this.unit.canDisableUnit = false;

        this.dialogRef.close(true);
      });
  }

  /**
   * Non productive categories are only part of the request,
   * if the unit is a level 3 unit.
   */
  private createUnit(): void {
    const request = UnitCreateRequestModel.fromJSON(this.getRequestData());

    // Notify application that unit was created
    this.unitService
      .postUnit$(request)
      .pipe(
        switchMap((response) => this.store.dispatch(new CreateUnit(response.unitId))
          .pipe(
            mapTo(response),
          )),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.saving = false;
        this.dialogRef.close(true);
        this.toastyService.success('Successfully created the unit.');
      }, (error: unknown) => {
        this.saving = false;
        this.errorHandlerService.handleError(error as Error, 'An error has occurred while creating the unit. Please try again.');
      });
  }

  /**
   * Non productive categories are only part of the request,
   * if the unit is a level 3 unit.
   */
  private editUnit(): void {
    const request = UnitUpdateRequestModel.fromJSON(this.getRequestData());

    // Notify the application that the unit was edited
    this.unitService
      .putUnit$(request)
      .pipe(
        switchMap(() => this.store.dispatch(new UpdateUnit(request.unitId))),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.saving = false;
        this.dialogRef.close(true);
        this.toastyService.success('Successfully updated the unit.');
      }, (error: unknown) => {
        this.saving = false;
        this.errorHandlerService.handleError(error as Error, 'An error has occurred while updating the unit. Please try again.');
      });
  }

  private patchForm(): void {
    if (this.unit) {
      this.parentUnit.disable();
      this.form.patchValue(this.unit);
      // Ensures that the unit itself can't be the parent
      this.disabledUnitItems = [this.unit];
      this.level = this.unit.level;
    } else {
      this.patchDefectList();
      this.patchDefaultNonProductiveCategories();
    }
  }

  /**
   * This gets the list of defects of a unit.
   * If there is no unit yet (creation process), a default list
   * will be provided.
   */
  private patchDefectList(): void {
    const unitId = this.unit != null ? this.unit.unitId : undefined;

    this.isLoadingDefectList = true;

    this.unitService
      .getUnitDefects$(unitId)
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Unit Defect List');

          return of([]);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((defects) => {
        this.defects.patchValue(defects);
        this.isLoadingDefectList = false;
      });
  }

  /**
   * This gets the list of non productive categories of a unit.
   * If there is no unit yet (creation process), a default list
   * will be provided.
   */
  private patchDefaultNonProductiveCategories(): void {
    this.isLoadingDefaultNonProductiveCategories = true;

    this.unitService
      .getDefaultNonProductiveCategories$()
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Non Productive Categories');

          return of([]);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((defaultNonProductiveCategories) => {
        this.nonProductiveCategories.patchValue(defaultNonProductiveCategories);
        this.isLoadingDefaultNonProductiveCategories = false;
      });
  }

  /**
   * This gets the timesheet export template of a unit.
   * If there is no unit yet (creation process), a default list
   * will be provided.
   */
  private getTimeSheetExportTemplate(unitId?: number): void {
    this.isLoadingTimesheetExportTemplate = true;

    this.unitService
      .getTimeSheetExportTemplate$(unitId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (timesheetExportTemplate) => {
          this.form.patchValue({
            timesheetExportTemplateColumns: timesheetExportTemplate.timesheetExportTemplateColumns,
            timesheetExportTemplateHighlighting: timesheetExportTemplate.timesheetExportTemplateHighlighting,
            timesheetExportNonProductiveTemplateColumns: timesheetExportTemplate
              .timesheetExportNonProductiveTemplateColumns,
            timesheetExportNonProductiveTemplateHighlighting: timesheetExportTemplate
              .timesheetExportNonProductiveTemplateHighlighting,
          });

          this.isLoadingTimesheetExportTemplate = false;

          // TODO: Is this necessary here? I believe it could be removed...
          this.updateFormValidation();
        },
        (error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'An error has occurred while loading the timesheet export template');
        },
      );
  }

  private initParentUnitListeners(): void {
    let isPayroleComanyInitialized = false;
    this.parentUnit
      .valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedUnit: Unit) => {
        if (selectedUnit) {
          // The (new) level of the unit, after changing the parent => ParentLevel+1
          const previousLevel = this.level;
          this.level = (selectedUnit.level + 1);

          if (this.canSeeTimesheetExportTemplate) {
            if (this.isLevel3 && !isPayroleComanyInitialized) {
              isPayroleComanyInitialized = true;
              this.initPayrollCoampanies();
              this.initPayrollCompanyListeners();
            }
            if (this.isLevel3 && previousLevel !== UnitLevel.Level3) {
              // Reload default NPCs and timesheet export template if selecting a L2 parent
              this.getTimeSheetExportTemplate(this.unit != null ? this.unit.unitId : undefined);
            } else if (this.isLevel4OrBelow) {
              // Reload correct NPCs and timesheet export template if selecting a L3/L4 parent
              this.getTimeSheetExportTemplate(selectedUnit.unitId);
            }
          }

          this.updateFormValidation();
        }
      });
  }

  private initPayrollCoampanies(): void {
    this.isLoadingPayrollCompanies.set(true);
    const examplePayrollCompanies = ['PC 1', 'PC 2', 'PC 3'];
    this.payrollCompanies.set(examplePayrollCompanies);
    this.isLoadingPayrollCompanies.set(false);
    this.unitService
      .getAllPayrollCompanies$()
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Payroll Companies');

          return of([]);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((payrollCompanies) => {
        this.isLoadingPayrollCompanies.set(false)
        this.payrollCompanies.set(payrollCompanies);
      });
  }

  private updateFormValidation(): void {
    this.updateTimesheetExportTemplateForm();
    this.updateNonProductiveCategoriesForm();
    this.updatePayrollCompanyForm();
  }

  private initPayrollCompanyListeners(): void {
    this.payroleCompany.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((payrollCompany) => !!payrollCompany),
        tap(() => this.isLoadingProfitCenters.set(true)),
        tap(() => this.profitCenters.set([])),
        tap(() => this.profitCenter.setValue('')),
        switchMap((payrollCompany) => this.unitService.getprofitCentersByPayrollCompany$(payrollCompany)),
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Profit Centers');

          return of([]);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((profitCenters) => {
        this.isLoadingProfitCenters.set(false)
        this.profitCenters.set(profitCenters);
        this.profitCenter.enable();
      });
  }

  private updatePayrollCompanyForm(): void {
    if (this.isLevel3) {
      this.payroleCompany.setValidators(Validators.required);
    } else {
      // make form not required
      this.payroleCompany.removeValidators(Validators.required);
    }
  }

  private updateTimesheetExportTemplateForm(): void {
    if (this.canEditUnitTimesheetExportTemplate) {
      this.timesheetExportTemplateColumns.setValidators([Validators.required]);
      this.timesheetExportTemplateColumns.enable();
      this.timesheetExportTemplateHighlighting.enable();
      this.timesheetExportNonProductiveTemplateColumns.setValidators([Validators.required]);
      this.timesheetExportNonProductiveTemplateColumns.enable();
      this.timesheetExportNonProductiveTemplateHighlighting.enable();
    } else {
      this.timesheetExportTemplateColumns.setValidators([]);
      this.timesheetExportTemplateColumns.disable();
      this.timesheetExportTemplateHighlighting.disable();
      this.timesheetExportNonProductiveTemplateColumns.setValidators([]);
      this.timesheetExportNonProductiveTemplateColumns.disable();
      this.timesheetExportNonProductiveTemplateHighlighting.disable();
    }
  }

  private updateNonProductiveCategoriesForm(): void {
    if (this.isLevel3OrBelow) {
      this.nonProductiveCategories.enable();
    } else {
      this.nonProductiveCategories.disable();
    }
  }

  private getRequestData(): UnitUpdateRequest {
    const {
      unitId,
      code,
      name,
      unitCoordinator,
      parentUnit,
      defects,
      deputyUnitCoordinators,
      submitterContracts,
      removedSubmitterContractIds,
      nonProductiveCategories,
      timesheetExportTemplateColumns,
      timesheetExportTemplateHighlighting,
      timesheetExportNonProductiveTemplateColumns,
      timesheetExportNonProductiveTemplateHighlighting,
      directUserSubmissionOfTimecard,
      hiddenLevels,
      payrollCompany,
      profitCenter,
    } = this.form.getRawValue();
    const costCenters = this.costCenterService.costCenters().map(item => item.toCostCenerSaveRequestJSON());
    const deletedActivityTypeIds = this.costCenterService.getDeletedActivityTypeIds();
    const deletedCostCentersIds = this.costCenterService.getDeletedCostCenterIds();

    return {
      costCenters,
      removedActivityTypeIds: deletedActivityTypeIds,
      removedCostCenterIds: deletedCostCentersIds,
      unitId,
      code,
      name,
      unitCoordinatorId: unitCoordinator.userId,
      parentUnitId: parentUnit ? parentUnit.unitId : null,
      defects,
      deputyUnitCoordinatorIds: deputyUnitCoordinators.map((item) => item.userId),
      submitterContracts: submitterContracts.map((item) => ({
        // TODO Better type enforcement
        supplierUnitId: item.supplierUnit!.unitId,
        submitterUnitId: item.submitterUnit?.unitId ?? null,
        contractId: item.contractId,
        model: item.model,
        contractHourlyRateCategoryPairs: [
          ...(Array.isArray(item.contractHourlyRateCategoryPairs) ? item.contractHourlyRateCategoryPairs : []),
        ],
        removedContractHourlyRateCategoryPairIds: item.removedContractHourlyRateCategoryPairIds,
        submitterCostCenter: item.costCenter,
        submitterEmployeeNumber: item.submitterEmployeeNumber,
        notificationSentTo: item.usersNotificationSentTo ? item.usersNotificationSentTo.map((user) => user.email).join(',') : '',
        costObject: item.costObjectCode,
        categoryActivityItem: item.categoryActivityItem,
      })),
      nonProductiveCategories,
      timesheetExportTemplateColumns,
      timesheetExportTemplateHighlighting,
      timesheetExportNonProductiveTemplateColumns,
      timesheetExportNonProductiveTemplateHighlighting,
      removedSubmitterContractIds,
      directUserSubmissionOfTimecard: directUserSubmissionOfTimecard || false,
      hiddenLevels,
      payrollCompany,
      profitCenter,
    };
  }
}
