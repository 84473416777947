import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AccessControlService } from '../services/access-control.service';
import { environment } from '../../environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable({
  providedIn: 'root',
})
export class ImpersonationInterceptor implements HttpInterceptor {
  constructor(private accessControlService: AccessControlService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the endpoint is not registered then pass
    // the request as it is to the next handler
    const isMystique = this.isMystique(request.url);
    if (!isMystique) {
      return next.handle(request);
    }

    if (
      this.accessControlService.substituteUserId
      && !request.headers.has('Mystique')
    ) {
      // Clone the request and replace the original headers with
      // cloned headers, updated with the new header params.
      const impersonatingRequest = request.clone({
        headers: request.headers.set('Mystique', this.accessControlService.substituteUserId),
      });

      return next.handle(impersonatingRequest);
    }

    return next.handle(request);
  }

  private isMystique(resource: string): boolean {
    return resource.includes(`${environment.apiEndpoint}`);
  }
}
