import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse, HttpSentEvent } from '@angular/common/http';
import {
  AnonymousCredential,
  BlobServiceClient,
  BlobUploadCommonResponse,
  ContainerClient,
  newPipeline,
} from '@azure/storage-blob';
import { AbortController } from '@azure/abort-controller';
import { TransferProgressEvent } from '@azure/core-http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { pathInfo } from '../helpers/path.utility';
import guid from '../helpers/guid';

/**
 * The number of characters in a GUID.
 */
const GUID_NUM_CHARS = 36;

export interface AzureBlobUploadResponse extends BlobUploadCommonResponse {
  blobUrl: string;
  blobName: string;
  fileName: string;
}

@Injectable({
  providedIn: 'root',
})
export class AzureBlobService {
  private readonly containerClient: ContainerClient;

  constructor() {
    // @TODO Create configurable service and inject options there instead of pulling
    // them here from the environment config
    const blobServiceUrl = environment.azureBlobServiceUrl;
    const blobContainerName = environment.azureBlobTemporaryAttachmentsContainer;

    const pipeline = newPipeline(new AnonymousCredential(), {
      // httpClient: MyHTTPClient, // A customized HTTP client implementing IHttpClient interface
      // logger: MyLogger, // A customized logger implementing IHttpPipelineLogger interface
      retryOptions: { maxTries: 4 }, // Retry options
      // telemetry: { value: 'HighLevelSample V1.0.0' }, // Customized telemetry string
    });

    const blobServiceClient = new BlobServiceClient(
      blobServiceUrl,
      // `https://${account}.blob.core.windows.net/${accountSas}`,
      pipeline,
    );

    this.containerClient = blobServiceClient.getContainerClient(blobContainerName);
  }

  /**
   * Uploads a given file to the configured Azure Blob Storage.
   */
  uploadFile$(file: File, blobNameMaxLength?: number): Observable<HttpEvent<AzureBlobUploadResponse>> {
    const fileSize = file.size || 1;
    const fileName = file.name;
    const { containerClient } = this;

    // Create a blob
    const blobName = this.getBlobName(file, blobNameMaxLength);
    const blobClient = containerClient.getBlobClient(blobName);
    const blockBlobClient = blobClient.getBlockBlobClient();

    const abortController = new AbortController();

    const observable$: Observable<HttpEvent<AzureBlobUploadResponse>> = new Observable((subject) => {
      const sentEvent: HttpSentEvent = { type: HttpEventType.Sent };
      subject.next(sentEvent);

      blockBlobClient
        .uploadBrowserData(file, {
          blobHTTPHeaders: {
            // Provide the mime type of the file, albeit unsafe, since otherwise
            // the blob storage does not set a mime type on the uploaded content.
            blobContentType: file.type,
          },
          // blockSize: 4 * 1024 * 1024, // 4MB block size
          // concurrency: 20, // 20 concurrency
          onProgress: (progress: TransferProgressEvent) => {
            const uploadProgressEvent: HttpProgressEvent = {
              type: HttpEventType.UploadProgress,
              loaded: progress.loadedBytes,
              total: fileSize,
            };
            subject.next(uploadProgressEvent);
          },
          abortSignal: abortController.signal,
        })
        .then((result) => {
          const body: AzureBlobUploadResponse = {

            ...result,
            blobUrl: blobClient.url,
            blobName,
            fileName,
          };
          const responseEvent = new HttpResponse<AzureBlobUploadResponse>({
            body,
          });
          subject.next(responseEvent);
          subject.complete();
        })
        .catch((error) => {
          const errorResponseEvent = new HttpErrorResponse({ error });
          subject.error(errorResponseEvent);
        });

      return (): void => {
        abortController.abort();
      };
    });

    return observable$;
  }

  /**
   * Get a unique file name for the blob storage to avoid naming conflicts.
   */
  getBlobName(file: File, blobNameMaxLength?: number): string {
    if (blobNameMaxLength && blobNameMaxLength < GUID_NUM_CHARS) {
      throw new Error(`The name of the blob file can't be less than ${GUID_NUM_CHARS} chars`);
    }

    const fileGuid = guid();
    const info = pathInfo(file.name);
    const fileExtension = info.extension;

    if (
      fileExtension
      && (
        !blobNameMaxLength
        || blobNameMaxLength > GUID_NUM_CHARS + 1 // Do not include the `.` dot
      )
    ) {
      const blobName = `${fileGuid}.${fileExtension.toLowerCase()}`;

      if (blobNameMaxLength) {
        return blobName.substr(0, blobNameMaxLength);
      }

      return blobName;
    }

    return fileGuid;
  }
}
