import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatButton } from '@angular/material/button';
import { Platform } from '@angular/cdk/platform';

/**
 * List of classes to add to CollappButton instances based on host attributes to
 * style as different variants.
 */
const BUTTON_HOST_ATTRIBUTES = [
  'collapp-button',
  'collapp-icon-button',
  'collapp-stroked-button',
  'collapp-flat-button',
  'collapp-sharp-edge-button',
];

/**
 * Material design Collapp button.
 */
@Component({
    selector: `button[collapp-button],
             button[collapp-icon-button],
             button[collapp-stroked-button],
             button[collapp-sharp-edge-button],
             button[collapp-flat-button]`,
    exportAs: 'collappButton',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.disabled]': 'disabled || null',
        '[class._mat-animation-noopable]': '_animationMode === "NoopAnimations"',
    },
    templateUrl: './button.html',
    // styleUrls: ['button.scss'],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['disabled', 'disableRipple', 'color'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CollappButtonComponent extends MatButton {
  /** Whether the button displays a loading indicator. */
  get loading(): boolean { return this._loading; }

  @Input()
  set loading(value: boolean) {
    this._loading = coerceBooleanProperty(value);
  }

  private _loading: boolean = false;

  constructor(
    elementRef: ElementRef,
    platform: Platform,
    ngZone: NgZone,
    // _focusMonitor: FocusMonitor,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) _animationMode: string,
  ) {
    super(elementRef,platform,ngZone, _animationMode);

    // For each of the variant selectors that is prevent in the button's host
    // attributes, add the correct corresponding class.
    BUTTON_HOST_ATTRIBUTES.forEach((attr) => {
      if (elementRef.nativeElement && elementRef.nativeElement.hasAttribute(attr)) {
         (elementRef.nativeElement as HTMLElement).classList.add(attr);
      }
    });
  }
}

/**
 * Material design Collapp anchor button.
 */
@Component({
    selector: `a[collapp-button],
             a[collapp-icon-button],
             a[collapp-stroked-button],
             a[collapp-flat-button]`,
    exportAs: 'collappButton, collappAnchor',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        // Note that we ignore the user-specified tabindex when it's disabled for
        // consistency with the `collapp-button` applied on native buttons where even
        // though they have an index, they're not tabbable.
        '[attr.tabindex]': 'disabled ? -1 : (tabIndex || 0)',
        '[attr.disabled]': 'disabled || null',
        '[attr.aria-disabled]': 'disabled.toString()',
        '(click)': '_haltDisabledEvents($event)',
        '[class._mat-animation-noopable]': '_animationMode === "NoopAnimations"',
    },
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['disabled', 'disableRipple', 'color'],
    templateUrl: './button.html',
    // styleUrls: ['button.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CollappAnchorComponent extends CollappButtonComponent {
  /** Tabindex of the button. */
  @Input() tabIndex?: number;

  constructor(
    elementRef: ElementRef,
    platform: Platform,
    ngZone: NgZone,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode: string,
  ) {
    super(elementRef, platform, ngZone, animationMode);
  }

  _haltDisabledEvents(event: Event): void {
    // A disabled button shouldn't apply any actions
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
