import { Pipe, PipeTransform } from '@angular/core';
import { GateDtoModel } from '../api/models/dtos/gate.dto.model';

@Pipe({
    name: 'collAppGate',
    standalone: false
})
export class CollAppGatePipe implements PipeTransform {
  transform(value?: GateDtoModel): any {
    if (value == null) {
      return '';
    }

    return value.name;
  }
}
