import { Pipe, PipeTransform } from '@angular/core';
import { TimeTrackingStatus, timeTrackingStatusCodeName } from '../../models/time-tracking-status.enum';

@Pipe({
    name: 'timeTrackingStatus',
    standalone: false
})
export class TimeTrackingStatusPipe implements PipeTransform {
  transform(status: TimeTrackingStatus): string {
    return timeTrackingStatusCodeName[status];
  }
}
