import { Pipe, PipeTransform } from '@angular/core';
import { TimeTrackingStatus } from '../../models/time-tracking-status.enum';

/**
 * This maps the generic time tracking states
 * to names that should be used in the time card view
 * @deprecated Use time-tracking-status.pipe.ts instead
 */
@Pipe({
    name: 'collAppTimecardStatus',
    standalone: false
})
export class CollAppTimecardStatusPipe implements PipeTransform {
  transform(value?: TimeTrackingStatus): any {
    if (value == null) {
      return '';
    }

    if (value === TimeTrackingStatus.Unknown) {
      return 'Unknown';
    }

    if (value === TimeTrackingStatus.Open) {
      return 'Open';
    }

    if (value === TimeTrackingStatus.Pending) {
      return 'Pending';
    }

    if (value === TimeTrackingStatus.Done) {
      return 'Approved';
    }

    if (value === TimeTrackingStatus.Rejected) {
      return 'Rejected';
    }

    if (value === TimeTrackingStatus.Submitted) {
      return 'Submitted';
    }

    if (value === TimeTrackingStatus.Ready) {
      return 'Ready';
    }

    if (value === TimeTrackingStatus.Reopen) {
      return 'Reopen';
    }

    throw new Error('Unexpected timecard status provided to the pipe');
  }
}
