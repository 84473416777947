import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { TOAST_DATA } from '../../shared/toasty';

@Component({
    templateUrl: './time-zone-notification.component.html',
    styleUrls: ['./time-zone-notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TimeZoneToastyComponent {
  constructor(
    @Inject(TOAST_DATA) public data: { timeZoneLabel: string },
  ) {
  }
}
