import {
  ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ColumnSelectorInfo } from './column-selector.component';

@Component({
    selector: 'collapp-column-selector-menu',
    templateUrl: './column-selector-menu.component.html',
    styleUrls: ['./column-selector-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColumnSelectorMenuComponent {
  @HostBinding('class')
  get class(): string { return 'collapp-column-selector-menu'; }

  @Input()
  isNewWay = false;

  @Input()
  set columns(value: ColumnSelectorInfo[]) {
    this.columnsInternal = (value || []).map((columnInfo) => Object.assign(Object.create(null), columnInfo));
  }

  @Output()
  // TODO rename
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change: EventEmitter<string[]> = new EventEmitter<string[]>(true);

  columnsInternal: ColumnSelectorInfo[] = [];

  onColumnSelectionChange(column: ColumnSelectorInfo, event: MatCheckboxChange): void {
    column.selected = event.checked; // eslint-disable-line no-param-reassign

    const selectedColumns = this.columnsInternal
      .filter((columnInfo) => columnInfo.selected)
      .map((columnInfo) => columnInfo.name);

    this.change.emit(selectedColumns);
  }

  trackByColumnName(index: number, column: ColumnSelectorInfo): string {
    return column.name;
  }
}
