import { Pipe, PipeTransform } from '@angular/core';
import { ContractHourlyRateCategoryPairDtoModel } from '../../api/models/dtos/contract-hourly-rate-category-pair.dto.model';
import { isEmpty } from '../../helpers/utilities';

export function getTextFromContractHourlyRateCategoryPairDtoModel(
  item: ContractHourlyRateCategoryPairDtoModel,
): string {
  return `${item.submitterHourlyRateCategory} / ${item.supplierHourlyRateCategory}`;
}

@Pipe({
    name: 'chrcPair',
    standalone: false
})
export class chrcPairPipe implements PipeTransform {
  transform(value: ContractHourlyRateCategoryPairDtoModel): string|null {
    if (isEmpty(value)) {
      return null;
    }

    return getTextFromContractHourlyRateCategoryPairDtoModel(value);
  }
}
