import { Pipe, PipeTransform } from '@angular/core';
import { RoleDtoModel } from '../api/models/dtos/role.dto.model';

@Pipe({
    name: 'collAppRole',
    standalone: false
})
export class CollAppRolePipe implements PipeTransform {
  transform(value?: RoleDtoModel | (RoleDtoModel)[]): any {
    if (value == null) {
      return '';
    }

    if (Array.isArray(value)) {
      return value
        .map((role: RoleDtoModel) => role.name)
        .join(', ');
    }

    return value.name;
  }
}
