import {
  Component, Inject, OnDestroy, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { BasicUser } from '../../../models/user.interface';
import { TypedFormGroup } from '../../../form/typed-form-group';
import { TypedFormBuilder } from '../../../form/typed-form-builder';
import { BasicDialogComponent } from '../basic-dialog/basic.dialog.component';
import { BasicDialogService } from '../../../services/basic-dialog.service';

/**
 * Data the dialog expects as input.
 */
export interface NotificationDialogData {
  title: string;
  message: string;
  recipients: BasicUser[];
  isCommentRequired: boolean;
  showSendNotifications: boolean;
}

/**
 * Response if dialog was confirmed
 */
export interface NotificationDialogResponse {
  comment: string;
  sendNotifications: boolean;
}

/**
 * Form of the dialog
 */
export interface NotificationDialogForm {
  comment: string;
  sendNotifications: boolean;
}

@Component({
    templateUrl: './notification-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./notification-dialog.component.scss'],
    standalone: false
})
export class NotificationDialogComponent implements OnDestroy {
  recipients: BasicUser[] = [];

  title: string = '';

  message: string = '';

  isCommentRequired: boolean = false;

  recipientsAsString: string = '';

  showSendNotifications: boolean = false;

  form: TypedFormGroup<NotificationDialogForm>;

  get comment(): UntypedFormControl { return this.form.controls.comment as UntypedFormControl; }

  get sendNotifications(): UntypedFormControl { return this.form.controls.sendNotifications as UntypedFormControl; }

  private confirmDialogRef: MatDialogRef<BasicDialogComponent> | null = null;

  /**
   * Triggers when the component is destroyed.
   * Necessary to inform other observers.
   *
   * @type {Subject<void>}
   * @private
   */
  private destroyed$: Subject<void> = new Subject();

  constructor(
    _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: NotificationDialogData,
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    private dialogService: BasicDialogService,
  ) {
    const {
      title, message, recipients, isCommentRequired, showSendNotifications,
    } = data;

    this.title = title;
    this.message = message;
    this.recipients = recipients;
    this.isCommentRequired = isCommentRequired;
    this.showSendNotifications = showSendNotifications;

    if (recipients && recipients.length > 0) {
      this.recipientsAsString = recipients
        .map((r) => r.fullName)
        .join(', ');
    }

    const formBuilder: TypedFormBuilder = _formBuilder as any;

    this.form = formBuilder.group<NotificationDialogForm>({
      comment: [
        undefined,
        this.isCommentRequired ? [Validators.required] : [],
      ],
      sendNotifications: [
        true,
      ],
    });
  }

  cancel(): void {
    if (this.confirmDialogRef) {
      return;
    }

    if (this.form.pristine) {
      this.dialogRef.close();

      return;
    }

    this.confirmDialogRef = this.dialogService.showConfirmDialog();

    this.confirmDialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((closeConfirmed) => {
        this.confirmDialogRef = null;
        if (closeConfirmed) {
          this.dialogRef.close();
        }
      });
  }

  confirm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();

      return;
    }

    this.dialogRef.close({
      comment: this.comment.value,
      sendNotifications: this.sendNotifications.value,
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
