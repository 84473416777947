/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ControlValueAccessor, UntypedFormControl, FormGroupDirective, NgControl, NgForm,
} from '@angular/forms';
import {
  ErrorStateMatcher,
  _ErrorStateTracker,
} from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  BehaviorSubject, merge, Observable, Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  share,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UserListViewResponseItemModel } from '../../api/models/dtos/user-list-view-response-item.dto.model';
import { UserDtoModel } from '../../api/models/dtos/user.dto.model';
import { UserService } from '../../api/services/user.service';
import { CompareWithUtility } from '../../helpers/compare-with.utility';

import { DEBOUNCE_INPUT_CHANGE_TIME_SMALL } from '../../shared/constants';
import { UserSlimDtoModel } from '../../api/models/dtos/user-slim.dto.model';
import { Unit } from '../../models/unit.interface';
import { BasicUser } from '../../models/user.interface';

import { ErrorHandlerService } from '../../services/error-handler.service';
import { UnitPlanningUserListViewResponseModel } from '../../api/models/responses/unit-planning-user-list-view.response.model';
import { UserListViewResponseModel } from '../../api/models/responses/user-list-view.response.model';
import { UnitPlanningResourceDtoModel } from '../../api/models/dtos/unit-planning-resource.dto.model';

type UserObject = UserDtoModel | UserListViewResponseItemModel | UserSlimDtoModel | UnitPlanningResourceDtoModel;

@Component({
    selector: 'collapp-people-select',
    exportAs: 'collappPeopleSelect',
    templateUrl: './people-select.component.html',
    styleUrls: ['./people-select.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: PeopleSelectComponent },
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PeopleSelectComponent<T extends BasicUser>
  implements OnInit, AfterViewInit, OnDestroy, DoCheck, OnChanges, ControlValueAccessor,
    MatFormFieldControl<T | T[]> {
  static nextId: number = 0;

  @HostBinding('class.collapp-people-select')
  readonly peopleSelectClass: boolean = true;

  // === MatFormFieldControl ===

  @Input()
  tabIndex: number = 0;

  @Input()
  disabled: boolean = false;

  @Input()
  disableRipple: boolean = false;

  /** The value of the control. */
  get value(): T | T[] | null {
    return this.userControl.value;
  }

  @Input()
  set value(value: T | T[] | null) {
    this.writeValue(value);
  }

  /** Already set through errorState mixin. */
  // public stateChanges: Observable<void>;

  /** The element ID for this control. */
  @Input()
  id: string = `collapp-people-select-${PeopleSelectComponent.nextId++}`; // eslint-disable-line no-plusplus

  @Input()
  isForWpCreator: boolean = false;

  @HostBinding('id')
  get hostId(): string {
    return this.id;
  }

  /** The placeholder for this control. */
  get placeholder(): string { return this._placeholder; }

  @Input()
  set placeholder(value: string) {
    if (this._placeholder !== value) {
      this._placeholder = value;
      this.stateChanges.next();
    }
  }

  /**
   * Gets the NgControl for this control.
   *
   * This is already set in the constructor and contrary to the interface
   * ngControl *can* be null if this component is not used as reactive form control.
   */
  // public ngControl: NgControl | null;

  /** Whether the control is focused. */
  get focused(): boolean {
    return this.userSelect.focused;
  }

  /** Whether the control is empty. */
  get empty(): boolean {
    return (
      this.userControl.value == null
      || (
        Array.isArray(this.userControl.value)
        && this.userControl.value.length === 0
      )
    );
  }

  /** Whether the `MatFormField` label should try to float. */
  @HostBinding('class.mat-form-field-should-float')
  get shouldLabelFloat(): boolean {
    return true;
  }

  /** Whether the component is required */
  @Input()
  get required(): boolean { return this._required; }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  /**
   * Whether the component is disabled
   *
   * Already set through the Disabled mixin.
   */
  // @Input()
  // public disabled: boolean;

  /**
   * Whether the control is in an error state.
   *
   * Already set through errorState mixin.
   */
  // public errorState: boolean;

  /** An object used to control when error messages are shown. */
  @Input()
  get errorStateMatcher(): ErrorStateMatcher {
    return this._errorStateTracker.matcher;
  }
  set errorStateMatcher(value: ErrorStateMatcher) {
    this._errorStateTracker.matcher = value;
  }

  // === MatFormFieldControl additional custom ===

  @HostBinding('attr.aria-describedby')
  describedBy: string = '';

  /**
   * This limits the search of users to this unit (including children).
   */
  @Input()
  get limitUnit(): Unit | null | undefined {
    return this._limitUnit;
  }

  set limitUnit(value: Unit | null | undefined) {
    this._limitUnit = value;
    this.refresh$.next();
  }

  /**
   * Whether to only load currently valid users
   */
  @Input()
  validOnly: boolean = false;

  /**
   * Used to fetch unit planning users.
   */
  @Input()
  get unitId(): number | undefined { return this._unitId; }

  set unitId(value: number | undefined) {
    if (value !== this.unitId) {
      this._unitId = value;
      this.refresh$.next();
    }
  }

  /**
   * Used to fetch unit planning users.
   */
  @Input()
  get isoWeekYear(): number | undefined { return this._isoWeekYear; }

  set isoWeekYear(value: number | undefined) {
    if (value !== this.isoWeekYear) {
      this._isoWeekYear = value;
      this.refresh$.next();
    }
  }

  /**
   * Used to fetch unit planning users.
   */
  @Input()
  get isoWeek(): number | undefined { return this._isoWeek; }

  set isoWeek(value: number | undefined) {
    if (value !== this.isoWeek) {
      this._isoWeek = value;
      this.refresh$.next();
    }
  }

  /**
   * Used to fetch unit planning users.
   */
  @Input()
  get numberOfWeeks(): number | undefined { return this._numberOfWeeks; }

  set numberOfWeeks(value: number | undefined) {
    if (value !== this.numberOfWeeks) {
      this._numberOfWeeks = value;
      this.refresh$.next();
    }
  }

  @Input()
  size: 'normal' | 'large' = 'normal';

  @Input()
  type: 'user' | 'resource' = 'user';

  get simple(): boolean { return this._simple; }

  @Input()
  set simple(value: boolean) {
    this._simple = coerceBooleanProperty(value);
  }

  @Input()
  appendTo: string = 'body';

  @Input()
  preload: boolean = false;

  @ViewChild('userSelect', { static: true })
  private userSelect!: NgSelectComponent;

  /**
   * An optional name for the control type that can be used to distinguish `mat-form-field` elements
   * based on their control type. The form field will add a class,
   * `mat-form-field-type-{{controlType}}` to its root element.
   */
  readonly controlType: string = 'people-select';

  /**
   * Whether the input is currently in an autofilled state. If property is not present on the
   * control it is assumed to be false.
   */
  readonly autofilled: boolean = false;

  // === /MatFormFieldControl ===

  // === /MatFormFieldControl additional custom ===

  // === Custom controls ===

  get multiple(): boolean { return this._multiple; }

  @Input()
  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  get localOnly(): boolean { return this._localOnly; }

  @Input()
  set localOnly(value: boolean) {
    this._localOnly = coerceBooleanProperty(value);
  }

  /**
   * Allows the currently selected value to be cleared.
   */
  get clearable(): boolean { return this._clearable; }

  @Input()
  set clearable(value: boolean) {
    this._clearable = coerceBooleanProperty(value);
  }

  @Input()
  get includeSupplierUsers(): boolean { return this._includeSupplierUsers; }

  set includeSupplierUsers(value: boolean) {
    this._includeSupplierUsers = coerceBooleanProperty(value);
  }

  @Input()
  get onlyCoordinators(): boolean { return this._onlyCoordinators; }

  set onlyCoordinators(value: boolean) {
    this._onlyCoordinators = coerceBooleanProperty(value);
  }

  // === \ Custom controls ===

  @HostBinding('class.collapp-people-select--large')
  get largeModifierClass(): boolean {
    return (this.size === 'large');
  }

  @HostBinding('class.collapp-people-select--multiple')
  get multipleModifierClass(): boolean {
    return this._multiple;
  }

  get personIconSize(): 'tiny' | 'small' {
    return this.size === 'normal' ? 'tiny' : 'small';
  }

  readonly usersCompare: (o1: any, o2: any) => boolean = CompareWithUtility.usersCompare;

  readonly planningResourceCompare: (o1: any, o2: any) => boolean = CompareWithUtility.planningResourceCompare;

  isLoading: boolean = false;

  listInitialized: boolean = false;

  filteredUsers$: Observable<UserObject[]>;

  numberOfResults: number = 0;

  numberOfMaxTotalResults: number = 0;

  // it must be public to use in the ng-select
  // eslint-disable-next-line rxjs/no-exposed-subjects
  typeahead$: Subject<string> = new Subject();

  userControl: UntypedFormControl = new UntypedFormControl();

  private _errorStateTracker: _ErrorStateTracker;

  // === MatFormFieldControl ===
  private _placeholder: string = '';

  private _required: boolean = false;
  // === \ MatFormFieldControl ===

  // === Custom controls ===
  private _multiple: boolean = false;

  private _localOnly: boolean = true;

  private _clearable: boolean = true;

  private _simple: boolean = false;

  private _limitUnit: Unit | null | undefined = null;

  private _isoWeekYear: number | undefined = undefined;

  private _isoWeek: number | undefined = undefined;

  private _unitId: number | undefined = undefined;

  private _numberOfWeeks: number | undefined = undefined;

  private _includeSupplierUsers: boolean = false;

  private _onlyCoordinators: boolean = false;
  // === \ Custom controls ===

  private filteredUsersSubject$: BehaviorSubject<UserObject[]> = new BehaviorSubject([]);

  private refresh$: Subject<void> = new Subject();

  private destroyed$: Subject<void> = new Subject();

  /**
   * Emits whenever the component state changes and should cause the parent
   * form-field to update. Implemented as part of `MatFormFieldControl`.
   * @docs-private
   */
  readonly stateChanges = new Subject<void>();

  /** Whether the chip grid is in an error state. */
  get errorState(): boolean {
    return this._errorStateTracker.errorState;
  }
  set errorState(value: boolean) {
    this._errorStateTracker.errorState = value;
  }

  constructor(
    _elementRef: ElementRef,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Self() @Optional() public ngControl: NgControl,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Attribute('tabindex') tabIndex: string,
    private readonly userService: UserService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    this._errorStateTracker = new _ErrorStateTracker(
      _defaultErrorStateMatcher,
      ngControl,
      _parentFormGroup,
      _parentForm,
      this.stateChanges,
    );

    this.tabIndex = Number.parseInt(tabIndex, 10) || 0;

    this.filteredUsers$ = this.filteredUsersSubject$.asObservable();
  }


  updateErrorState(): void {
    this._errorStateTracker.updateErrorState();
  }

  // eslint-disable-next-line max-lines-per-function
  ngOnInit(): void {
    this.userControl
      .valueChanges
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        this._onChange(value);
        this.stateChanges.next();
      });

    const initialDropDownOpen$ = this.userSelect.openEvent
      .pipe(
        filter(() => !this.listInitialized),
        mapTo(''),
        takeUntil(this.destroyed$),
        share(),
      );

    const dropdown$ = merge(
      initialDropDownOpen$,
      this.typeahead$,
    )
      .pipe(
        debounceTime(DEBOUNCE_INPUT_CHANGE_TIME_SMALL),
        map((value) => (value ? value.trim() : '')),
        distinctUntilChanged(),
      );

    // Listens for changes in the filterable dropdown
    merge(
      dropdown$,
      this.refresh$,
    )
      .pipe(
        map((value) => (value ? value.trim() : '')),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap((filterString) => {
          // eslint-disable-next-line max-len
          const request$: Observable<UserListViewResponseModel | UnitPlanningUserListViewResponseModel> = this.prepareRequest$(filterString);

          return request$.pipe(
            tap((response) => {
              if (filterString === '') {
                this.numberOfMaxTotalResults = response.metadata.paginationInfo.numberOfTotalResults || 0;
              }
            }),
          );
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (response) => {
          this.processUserListResponse(response);
        },
        (error: unknown) => {
          this.isLoading = false;
          this.errorHandlerService.handleError(error as Error, 'Error occurred while fetching users');
        },
      );
  }

  ngAfterViewInit(): void {
    if (this.preload) {
      this.preloadResources();
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Updating the disabled state is handled by `mixinDisabled`, but we need to additionally let
    // the parent form field know to run change detection when the disabled state changes.
    if (changes.disabled) {
      this.setUserControlDisabledState(changes.disabled.currentValue);
      this.stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    this.filteredUsersSubject$.complete();
    this.refresh$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // =========

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   *
   * @param value
   */
  writeValue(value: any): void {
    this.userControl.setValue(value);
    this.stateChanges.next();
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   *
   * @param fn - Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   *
   * @param fn - Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   *
   * @param {boolean} isDisabled - Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.setUserControlDisabledState(isDisabled);
    this.stateChanges.next();
  }

  // === /ControlValueAccessor ===

  // === MatFormFieldControl ===

  /** Sets the list of element IDs that currently describe this control. */
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  /** Handles a click on the control's container. */
  onContainerClick(): void {
    // Nothing
  }

  // === /MatFormFieldControl ===

  userTrackByFn(index: number, user: UserObject): string {
    if (user instanceof UnitPlanningResourceDtoModel) {
      return user.planningResourceId;
    }

    return user.userId;
  }

  onBlur(): void {
    this.ngControl.control?.markAsTouched();
  }

  private setUserControlDisabledState(disabled: boolean): void {
    if (this.userControl.disabled !== disabled) {
      if (disabled) {
        this.userControl.disable();
      } else {
        this.userControl.enable();
      }
    }
  }

  /**
   * Prepare the requestObservable
   * @param filterString
   * @returns
   */
  // eslint-disable-next-line max-len
  private prepareRequest$(filterString: string): Observable<UserListViewResponseModel | UnitPlanningUserListViewResponseModel> {
    const isUserPlanningDataAvailable = this.unitId && this.isoWeekYear && this.isoWeek && this.numberOfWeeks;

    const getUserPlanningData$ = this.userService.getUserPlanningResources$(
      <number> this.unitId,
      filterString,
      <number> this.isoWeekYear,
      <number> this.isoWeek,
      <number> this.numberOfWeeks,
      this.isForWpCreator,
    );

    const getUsers$ = this.userService.findUsers$(
      filterString,
      this.localOnly,
      this.validOnly,
      this.limitUnit,
      this.includeSupplierUsers,
      this.onlyCoordinators,
      this.isForWpCreator,
    );

    return isUserPlanningDataAvailable ? getUserPlanningData$ : getUsers$;
  }

  /**
   * first call to API.. the API will then put it in cache
   */
  private preloadResources(): void {
    const request$ = this.prepareRequest$('');
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    request$.pipe(take(1)).subscribe((response) => {
      this.processUserListResponse(response);
      if (response) {
        this.numberOfMaxTotalResults = response.metadata.paginationInfo.numberOfTotalResults || 0;
      }
    });
  }

  private processUserListResponse(response: UserListViewResponseModel | UnitPlanningUserListViewResponseModel): void {
    this.isLoading = false;

    if (!response) {
      return;
    }

    const users = [...response.items];
    const numberOfUsers = users.length;

    this.filteredUsersSubject$.next(users);
    this.numberOfResults = numberOfUsers;

    this.listInitialized = true;
  }

  /**
   * onChange function registered via registerOnChange (ControlValueAccessor).
   */
  private _onChange: (value: any) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  /**
   * onTouch function registered via registerOnTouch (ControlValueAccessor).
   */
  private _onTouched: () => any = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  // =========
}
