import {
  Component, Inject, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ContractHourlyRateCategoryPairDtoModel } from '../../../api/models/dtos/contract-hourly-rate-category-pair.dto.model';
import { ContractDtoModel } from '../../../api/models/dtos/contract.dto.model';
import { UnitSlimDtoModel } from '../../../api/models/dtos/unit-slim.dto.model';
import { TypedFormBuilder } from '../../../form/typed-form-builder';
import { TypedFormGroup } from '../../../form/typed-form-group';
import { AddAdditionalSuppliersDialogCloseData, AddAdditionalSuppliersDialogData } from './collapp-wp-add-additional-suppliers-dialog.types';

export interface AdditionalSupplierForm {
  supplierUnit: UnitSlimDtoModel;
  supplierHourlyRateCategory: ContractHourlyRateCategoryPairDtoModel;
}

// TODO: Once nedest value binding is supported https://github.com/ng-select/ng-select/blob/master/src/demo/app/examples/bindings-nested-example/bindings-nested-example.component.html
// we can just use contracts instead of extracting and filtering the suppliers

@Component({
    selector: 'collapp-collapp-wp-add-additional-suppliers-dialog',
    templateUrl: './collapp-wp-add-additional-suppliers-dialog.component.html',
    styleUrls: ['./collapp-wp-add-additional-suppliers-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CollappWpAddAdditionalSuppliersDialogComponent implements OnInit, OnDestroy {
  contracts: ContractDtoModel[] = [];

  contractHourlyRateCategoryPairs: ContractHourlyRateCategoryPairDtoModel[] = [];

  form: TypedFormGroup<AdditionalSupplierForm>;

  isDropdownOpen = false;

  get supplierControl(): UntypedFormControl { return this.form.controls.supplierUnit as UntypedFormControl; }

  get supplierHourlyRateCategoryControl(): UntypedFormControl {
    return this.form.controls.supplierHourlyRateCategory as UntypedFormControl;
  }

  suppliers: (UnitSlimDtoModel | null)[] = [];

  private destroyed$: Subject<void> = new Subject();

  constructor(
  @Inject(MAT_DIALOG_DATA) data: AddAdditionalSuppliersDialogData,
    public dialogRef: MatDialogRef<
    CollappWpAddAdditionalSuppliersDialogComponent,
    AddAdditionalSuppliersDialogCloseData>,
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.contracts = data.contracts;
    this.suppliers = this.contracts.map((c) => c.supplierUnit).filter((u) => !!u);

    const formBuilder: TypedFormBuilder = _formBuilder as any;
    this.form = formBuilder.group<AdditionalSupplierForm>({
      supplierUnit: [null, Validators.required],
      supplierHourlyRateCategory: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.supplierControl.valueChanges.pipe(
      tap(() => this.supplierHourlyRateCategoryControl.reset()),
      takeUntil(this.destroyed$),
    ).subscribe((selectedSupplierUnit) => {
      const filteredContracts = this.contracts.filter((c) => c.supplierUnit?.unitId === selectedSupplierUnit?.unitId);
      // eslint-disable-next-line arrow-body-style
      this.contractHourlyRateCategoryPairs = filteredContracts.reduce(
        (acc, c) => acc.concat(c.contractHourlyRateCategoryPairs),
        [] as ContractHourlyRateCategoryPairDtoModel[],
      );
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close({
      chrcps: this.supplierHourlyRateCategoryControl.value,
    });
  }

  onOpen(): void {
    this.isDropdownOpen = true;
  }

  onClose(): void {
    this.isDropdownOpen = false;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
