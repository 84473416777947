import {
  ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output,
} from '@angular/core';
import { ListColumnInfo } from '../../api/meta-info/base';
import { ReadonlyCompleteMap } from '../../app.types';

export interface ColumnSelectorInfo<T extends string = string> {
  name: T;
  label: string;
  selected: boolean;
}

/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * Constructs a ColumnSelectorInfo array that can be consumed by the <collapp-column-selector>.
 */
export function composeColumnSelectorInfoForList<P extends string, T extends ReadonlyCompleteMap<P, ListColumnInfo>>(
  listColumnsInfo: T,
  selectedColumns: P[],
): ColumnSelectorInfo<P>[] {
  const colInfo = Array.from(listColumnsInfo.entries())
    .filter(([_key, value]) => value.selectable)
    .map(([key, value]) => ({
      name: key,
      label: value.label,
      selected: selectedColumns.includes(key),
    }));
  colInfo.toString = (): string => colInfo.map((col) => col.name).join(', ');

  return colInfo;
}

/* eslint-enable @typescript-eslint/no-unused-vars */

@Component({
    selector: 'collapp-column-selector',
    templateUrl: './column-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColumnSelectorComponent {
  get columnsInfo(): ColumnSelectorInfo[] {
    return this._columnsInfo;
  }

  @Input()
  set columnsInfo(value: ColumnSelectorInfo[]) {
    const setColumnInfo = (value || []).map((item) => ({ ...item }));
    setColumnInfo.toString = (): string => setColumnInfo.map((col) => col.name).join(', ');
    this._columnsInfo = setColumnInfo;
  }

  @Output()
  // TODO rename
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change: EventEmitter<string[]> = new EventEmitter<string[]>(true);

  @HostBinding('class')
  get class(): string { return 'collapp-column-selector'; }

  private _columnsInfo: ColumnSelectorInfo[] = [];

  preventMenuFromClosing(event: MouseEvent): void {
    event.stopPropagation();
  }

  triggerChange(event: string[]): void {
    this.change.emit(event);
  }
}
