import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../helpers/utilities';

@Pipe({
    name: 'responsiblesTooltipPipe',
    standalone: false
})
export class ResponsiblesTooltipPipe implements PipeTransform {
  transform(value: string): string | null {
    if (!isEmpty(value) && value.includes(', ')) {
      const listOfResponsibles = value.split(', ');
      if (listOfResponsibles.length < 3) {
        return null;
      }

      return value;
    }

    return null;
  }
}
