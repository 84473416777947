import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AccessControlError, AccessControlService } from '../../services/access-control.service';

@Component({
    templateUrl: './logout.component.html',
    standalone: false
})
export class LogoutComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();

  constructor(
    public accessControlService: AccessControlService,
  ) {
  }

  ngOnInit(): void {
    if ((this.authenticated || this.authorized) && !this.error) {
      this.logout();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  login(): void {
    this.accessControlService.login();
  }

  logout(): void {
    this.accessControlService.logout();
  }

  get authenticated(): boolean {
    return this.accessControlService.authenticated;
  }

  get authorized(): boolean {
    return this.accessControlService.authorized;
  }

  get error(): AccessControlError | null {
    return this.accessControlService.error;
  }
}
