import { Pipe, PipeTransform } from '@angular/core';
import { WorkPackageRole } from '../../models/work-package-role.enum';

const role: {[key in WorkPackageRole]: string } = Object.freeze({
  [WorkPackageRole.NoRole]: 'NoRole',
  [WorkPackageRole.WorkPackageCreator]: 'Work Package Creator',
  [WorkPackageRole.ProjectManager]: 'Project Manager',
  [WorkPackageRole.TechnicalProjectManager]: 'Technical Project Manager',
  [WorkPackageRole.SubmitterUnitCoordinator]: 'Submitter Unit Coordinator',
  [WorkPackageRole.SubmitterUnitL1Coordinator]: 'Submitter Unit Coordinator (L1)',
  [WorkPackageRole.SubmitterUnitL2Coordinator]: 'Submitter Unit Coordinator (L2)',
  [WorkPackageRole.SubmitterUnitL3Coordinator]: 'Submitter Unit Coordinator (L3)',
  [WorkPackageRole.SubmitterUnitL4Coordinator]: 'Submitter Unit Coordinator (L4)',
  [WorkPackageRole.SubmitterUnitL5Coordinator]: 'Submitter Unit Coordinator (L5)',
  [WorkPackageRole.SupplierUnitCoordinator]: 'Supplier Unit Coordinator',
  [WorkPackageRole.SupplierUnitL1Coordinator]: 'Supplier Unit Coordinator (L1)',
  [WorkPackageRole.SupplierUnitL2Coordinator]: 'Supplier Unit Coordinator (L2)',
  [WorkPackageRole.SupplierUnitL3Coordinator]: 'Supplier Unit Coordinator (L3)',
  [WorkPackageRole.SupplierUnitL4Coordinator]: 'Supplier Unit Coordinator (L4)',
  [WorkPackageRole.SupplierUnitL5Coordinator]: 'Supplier Unit Coordinator (L5)',
  [WorkPackageRole.WorkPackageCoordinator]: 'Work Package Coordinator',
  [WorkPackageRole.WorkPackageResponsible]: 'Work Package Responsible',
  [WorkPackageRole.Administrator]: 'Administrator',
});

@Pipe({
    name: 'collAppWorkPackageRole',
    standalone: false
})
export class CollAppWorkPackageRolePipe implements PipeTransform {
  transform(value?: WorkPackageRole | null): string {
    if (value == null) {
      return '';
    }

    if (role[value] != null) {
      return role[value];
    }

    return '';
  }
}
