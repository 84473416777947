import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'safeHtml',
    standalone: false
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitized: DomSanitizer) {}

  transform(value: any): any {
    return this.domSanitized.bypassSecurityTrustHtml(value);
  }
}
