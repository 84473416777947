<ng-select
  #userSelect
  ngSelectMat
  class="collapp-people-select__select"
  [formControl]="userControl"
  [items]="(filteredUsers$ | async) || []"
  [loading]="isLoading"
  [typeahead]="typeahead$"
  [bindLabel]="'fullName'"
  [compareWith]="unitId && isoWeekYear && isoWeek && numberOfWeeks ? planningResourceCompare : usersCompare"
  [placeholder]="placeholder"
  [notFoundText]="'No ' + (type === 'user' ? 'users' : 'resources') + ' found'"
  [multiple]="multiple"
  [clearable]="clearable"
  [required]="required"
  [appendTo]="'body'"
  (blur)="onBlur()"
  >
  <ng-template ng-label-tmp let-item="item">
    <div class="ng-value-label">
      <person [user]="item" [size]="personIconSize" [disabled]="disabled"></person>
    </div>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    @if (simple) {
      {{ items.length }} selected
    }
    @if (!simple) {
      @for (item of items; track userTrackByFn($index, item)) {
        <div class="ng-value">
          <div class="ng-value-label">
            <person [user]="item" [size]="personIconSize" [disabled]="disabled"></person>
          </div>
          <div class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</div>
        </div>
      }
    }
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <person [user]="item" [size]="'small'" [disabled]="disabled"></person>
  </ng-template>
  <ng-template ng-footer-tmp>
    @if (listInitialized) {
      @if (localOnly && numberOfResults <= numberOfMaxTotalResults) {
        Displaying {{numberOfResults}} of {{numberOfMaxTotalResults}} available {{ type === 'user' ? 'users' : 'resources' }}
      } @else {
        Displaying {{numberOfResults}} filtered {{ type === 'user' ? 'users' : 'resources' }}
      }
    }
  </ng-template>
</ng-select>
