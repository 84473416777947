import {
  Component, ElementRef, Input, OnInit, ViewChild,
} from '@angular/core';

/**
 * @title Configurable progress-bar
 */
@Component({
    selector: 'collapp-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    standalone: false
})
export class ProgressBarComponent implements OnInit {
  @Input() color: string = 'primary';

  @Input() mode: string = 'determinate';

  @Input()
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
    this.setProgress(this.value);
  }

  @ViewChild('progressBar', { static: true }) progressBar!: ElementRef;

  @ViewChild('progressBarFill', { static: true })
  progressBarFill!: ElementRef;

  @ViewChild('progressBarLabelLight', { static: true })
  progressBarLabelLight!: ElementRef;

  progressBarWarn: boolean = false;

  progressBarLabel: number = 0;

  private _value: number = 0;

  ngOnInit(): void {
    this.setProgress(this.value);
  }

  private setProgress(progressValue: number): void {
    let newValue = progressValue;

    if (newValue > 100) {
      this.progressBarWarn = true;
      newValue = 100;
    }

    if (newValue < 0) {
      this.progressBarWarn = false;
      newValue = 0;
    }

    this.progressBarLabel = this.value;
    this.progressBarFill.nativeElement.style.width = progressValue > 100 ? '100%' : `${progressValue}%`;
  }
}
