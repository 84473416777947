import { Pipe, PipeTransform } from '@angular/core';
import { BasicUser, MinimalUser } from '../../models/user.interface';
import { isEmpty } from '../../helpers/utilities';
import { NonHumanResourceSlimDto } from '../../api/interfaces/dtos/non-human-resource-slim.dto';

/**
 * Returns a string representation of a given user or list of users.
 *
 * @param [value]
 * @param [defaultValue]
 */
// eslint-disable-next-line complexity
export function formatCollAppUser(
  value: MinimalUser |BasicUser | BasicUser[] | NonHumanResourceSlimDto | null | undefined,
  defaultValue?: string,
): string | null {
  if (isEmpty(value)) {
    return (defaultValue != null ? defaultValue : null);
  }

  if (Array.isArray(value)) {
    return value
      .map((user) => formatCollAppUser(user, defaultValue))
      .filter((user) => user != null)
      .join(', ');
  }

  if ('fullName' in value && value.fullName) {
    return value.fullName;
  }

  if ('email' in value && value.email) {
    return value.email;
  }

  if ('name' in value && value.name) {
    return value.name;
  }

  return (defaultValue != null ? defaultValue : null);
}

@Pipe({
    name: 'collAppUser',
    standalone: false
})
export class CollAppUserPipe implements PipeTransform {
  transform(value?: MinimalUser | BasicUser | BasicUser[] | null, defaultValue?: string): string | null {
    return formatCollAppUser(value, defaultValue);
  }
}
