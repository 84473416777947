import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../../helpers/utilities';
import { QuestionStatus } from '../../models/question-status.enum';

@Pipe({
    name: 'collappQuestionStatus',
    standalone: false
})
export class CollappQuestionStatusPipe implements PipeTransform {
  transform(value: QuestionStatus): string | null {
    if (isEmpty(value)) {
      return null;
    }

    if (value === QuestionStatus.Open) {
      return 'Open';
    }

    if (value === QuestionStatus.Concluded) {
      return 'Concluded';
    }

    return 'Unknown Status';
  }
}
